import React from 'react'
import { Link } from 'gatsby'
import { DefaultLayout } from '../components/Layouts'
import Meta from '../components/Meta'

const linkClasses = 'underline text-grey-700 hover:text-grey-900'

const NotFoundPage = () => (
  <DefaultLayout>
    <Meta title="404" />
    <div className="text-center text-grey-600 max-w-xl">
      <h1 className="font-bold text-4xl text-black mb-2">
        There's nothing here
      </h1>
      <p className="mb-2">
        ...but there could be! Contact us to create your brand's style guide.
      </p>
      <p>
        <Link to="/" className={linkClasses}>
          Return to the homepage
        </Link>{' '}
        or send a message to{' '}
        <a href="mailto:stephy@northxsouth.co" className={linkClasses}>
          stephy@northxsouth.co
        </a>{' '}
        for assistance.
      </p>
    </div>
  </DefaultLayout>
)

export default NotFoundPage
